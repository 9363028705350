import { Box, Button, TextField, Typography, InputAdornment, IconButton, ButtonGroup, Link, Backdrop, CircularProgress } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { orange } from "@mui/material/colors"
import {ReactComponent as ImgCongrats} from '../../assets/images/congrats.svg'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'

const learntKey = `learnt_${new Date().toLocaleDateString()}`
let wordsWithCn: string[] = []

const Words = () => {
  const [inputVal, setInputVal] = useState('')
  const [showExplain, setEhowExplain] = useState(false)
  const [cn, setCn] = useState('雅思随机单词')
  const [en, setEn] = useState('')
  const [learnt, setLearnt] = useState<number>( Number(localStorage.getItem(learntKey)||'0') )
  const [showTips, setShowTips] = useState(false)
  const [showUsage, setShowUsage] = useState(true)
  const [showBackdrop, setShowBackDrop] = useState(false)
  const [inputError, setInputError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isBlur, setIsBlur] = useState(true)

  const inputRef = useRef()

  useEffect(()=>{
    (inputRef.current as any)?.focus()
    fetch('./files/Oxford-5000-cn.txt').then(res=>res.text()).then(text=>{
      wordsWithCn = text.split('\n')
      randomWord()
      setLoading(false)
    })
  }, [])

  useEffect(()=>{
    window.addEventListener('keydown', keyReturnPress)
    return () => {
      window.removeEventListener('keydown', keyReturnPress)
    }
  }, [inputVal, showTips, en, isBlur])

  useEffect(()=>{
  }, [en, inputVal, showTips])

  useEffect(()=>{
    localStorage.setItem(learntKey, learnt.toString())
  }, [learnt])

  function inputFocus() {
    setIsBlur(false)
    setInputVal('')
    setEhowExplain(false)
  }

  function randomWord(delayMs=0) {
    setTimeout(()=>{
      setShowBackDrop(false)
      const index = Math.floor( Math.random() * wordsWithCn.length)
      const _en = wordsWithCn?.[index]?.split('->')?.[0] || ''
      const _cn = wordsWithCn?.[index]?.split('->')?.[1]?.replace(_en, '').replace(FirstCapital(_en), '') || ''
      console.log(_cn)
      setEn(_en)
      setCn(_cn)
      setShowTips(false);
      (inputRef.current as any)?.focus()
      inputFocus()
    }, delayMs)
  }

  function keyReturnPress(e: any) {
    if ('Enter'===e.key) {
      check()
      return
    }
    if ( isBlur ) (inputRef.current as any)?.focus()
  }

  function check() {
    const checked = inputVal.trim().toLowerCase()===en.toLowerCase()
    if (checked) {
      setShowBackDrop(true)
      randomWord(618)
      setLearnt(prevLearnt=>prevLearnt+1);
      (inputRef.current as any)?.blur()
    } else {
      errorEffect()
      if (showTips) {
        (inputRef.current as any)?.blur()
        setEhowExplain(true)
      }
      setInputVal('')
      setShowTips(true)
    }
  }

  async function errorEffect() {
    setInputError(true)
    await new Promise((resolve, _)=>{setTimeout(()=>{resolve(null)}, 500)})
    setInputError(false)
  }

  return (
    <Box className="flex flex-col h-screen mx-auto max-w-[720px] px-4">
      <Box className="w-full">
        <Box id='top-cn' className={``} style={{padding: `${showExplain?0:30}px 0`}}>
          <Typography variant={`h${showExplain?5:4}`}
            className="first-letter:uppercase font-bold text-orange-600"
          >
            {window.location.hostname}
          </Typography>
        </Box>
        <Typography id='cn' className="line-clamp-2">{cn}</Typography>
        <Box id='en-box' className="my-1">
          <TextField 
          inputRef={inputRef}
          onBlur={()=>setIsBlur(true)}
          label={showTips?tips(en):''}
          onFocus={inputFocus}
          error={inputError}
          value={inputVal}
          placeholder="输入单词 或 直接回车" 
          fullWidth
          inputProps={{style: {fontSize: '24px', fontWeight: 'bold'}}}
          onChange={(e)=>setInputVal(e.target.value)}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={check}><KeyboardReturnIcon /></IconButton>
              </InputAdornment>
            ),
          }}/>
        </Box>
        <Box className='flex items-center'>
          <MilitaryTechIcon color="warning" className="mr-1" />
          <Typography>今日学习 <span style={{color: orange[800], fontWeight: 'bold', fontSize: '23px'}}>{learnt}</span> 个单词</Typography>
        </Box>

        <Box className="mb-5">
          <Link target="_blank"
            href="https://english.shuzijumin.com"
          >👉新软件：100 个句子记完 7000 个雅思单词</Link>
        </Box>

        {!showExplain&&showUsage&&!localStorage.getItem('neverShowUsage')&&<Box className="mt-4">
          <Typography>提示：</Typography>
          <Typography>1. 输入单词，回车，第一次错误显示提示，第二次错误显示详情</Typography>
          <Typography>2. 直接回车查看提示，再次回车查看详情</Typography>
          <Typography>3. 详情里如果有视频建议多看，结合场景加深记忆</Typography>
          <Typography>4. 词库：<Link href="https://www.oxfordlearnersdictionaries.com/external/pdf/wordlists/oxford-3000-5000/American_Oxford_5000.pdf" target='_blank'>Oxford 5000, B2-C1</Link></Typography>
          <Typography>5. Contact: theduckyenglish@gmail.com</Typography>
          <ButtonGroup className="mt-2" variant="outlined">
            <Button onClick={()=>setShowUsage(false)}>知道了</Button>
            <Button onClick={()=>{localStorage.setItem('neverShowUsage', 'yes'); setShowUsage(false)}}>不再显示</Button>
          </ButtonGroup>
        </Box>}
      </Box>
      {showExplain&&<Box id="iframe-box" className="grow w-full">
        <iframe style={{maxWidth: '750px'}}
        // src={`https://m.openlanguage.com/m/g/dictionary_tt/?text=${en}&from_to=en_to_cn`}
        src={`https://dict.youdao.com/m/result?word=${en}&lang=en`}
        className="w-full border-0 h-full block" referrerPolicy="no-referrer" />
      </Box>}
      {showBackdrop&&<Backdrop open className="z-50">
        <Box className="flex flex-col items-center">
          <ImgCongrats width='34vw' height='34vw' />
          <Typography style={{color: orange[800], fontWeight: 'bold', fontSize: '64px'}}>
            Bravo!
          </Typography>
        </Box>
      </Backdrop>}
      {loading&&<Backdrop open className="flex-col">
        <CircularProgress color="warning" />
        <Typography color={orange[800]}>Loading...</Typography>
      </Backdrop>}
    </Box>
  )
}

export default Words

function tips(word: string): string {
  return `${word.slice(0, 1)}${' _'.repeat(word.length-2)} ${word.slice(-1)}`
}

function FirstCapital(word: string): string {
  if (!word.trim()) return ''
  return word[0].toUpperCase() + word.substring(1)
}