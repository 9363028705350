import { useEffect } from "react"
import { observer } from "mobx-react-lite"
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box } from "@mui/material";

const Dev = observer(() => {
  useEffect(()=>{
    foo()
    const interval = setInterval(()=>{
      foo()
    }, 3000)
    return () =>{
      clearInterval(interval)
    }
  }, [])
  
  function foo() {
    console.log('foo')
  }
  return (
    <Box className="h-screen w-screen flex items-center justify-center">
      <VideoLibraryIcon sx={{color: '#ff9100', width: 1200, height: 1200}} />
    </Box>
  )
})
export default Dev